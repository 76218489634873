// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-create-sell-page-v-1-tsx": () => import("./../../../src/pages/create/SellPageV1.tsx" /* webpackChunkName: "component---src-pages-create-sell-page-v-1-tsx" */),
  "component---src-pages-create-tsx": () => import("./../../../src/pages/create.tsx" /* webpackChunkName: "component---src-pages-create-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-product-tsx": () => import("./../../../src/pages/product.tsx" /* webpackChunkName: "component---src-pages-product-tsx" */)
}

